import { getResponseV2 } from '@arkadium/jarvis';

export type JarvisReturn = ReturnType<typeof getResponseV2>;
export type JarvisResponse = Awaited<JarvisReturn>;
export type Config = JarvisResponse['config'];

export class JarvisService {
  public fetched: JarvisReturn;

  constructor(protected host: string) {
    this.fetched = getResponseV2(this.host);
  }

  fetch() {
    return this.fetched;
  }
}

