import { useEffect } from "preact/hooks";
import { connect, actions, State } from '../store/';

import { MuteButton } from "./MuteButton";

import "./SafeCountdown.css";

export default connect(['generalTimeLeft', 'generalDuration', 'generalState', 'videoAdCount'], actions)(({ finish, generalDuration, generalTimeLeft, generalState, setVolume, videoAdCount }: any) => {
  const initialTime = generalDuration;
  useEffect(() => {
    if (generalTimeLeft < 1) {
      finish();
    }
  }, [generalTimeLeft]);
  return (
    <div className="avp-panel">
      <div className="avp-panel-top">
        <div className="avp-panel-mute"><MuteButton onToggle={setVolume}/></div>
      </div>
      <div className="avp-panel-progress">
        <div className="avp-panel-progress-line" style={{ width: (initialTime ? 100/initialTime*(initialTime-generalTimeLeft) : 0) + '%' }}></div>
      </div>
    </div>
  );
});
