import DisplayFallback from "./components/DisplayFallback.tsx";

import PlayerIMA from './video/IMAPlayer';
import CTAScreen from "./components/CTAScreen";
import SafeCountdown from "./components/SafeCountdown";

import {IStore, State, connect, actions} from "./store";

export const TypePreroll: React.FC<IStore> = ({ generalState, videoIMAFailed }) => {
  const Player = PlayerIMA;

  if (generalState === State.FINISH) {
    return <></>;
  }

  return (
    <>
      { generalState === State.FALLBACK ? <DisplayFallback /> : null }
      { generalState !== State.FALLBACK ? <CTAScreen><Player /></CTAScreen> : null }
      { generalState !== State.CTA && <SafeCountdown /> }
    </>
  );
};

export default connect(['generalState', 'videoIMAFailed'], actions)(TypePreroll);

