import { loadPrebid } from "@arkadium/jarvis";
import { getSupportedMimes } from "../../utils/mimes";
import { JarvisResponse } from "../jarvis-service";
import { IBid, IProvider } from "../provider";
import { GeoService } from "../geo";
import { KeyValues } from "../keyvalues";

const GDPRCountryCodes = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'GF', 'GP', 'MQ', 'ME', 'YT', 'RE', 'MF', 'GI', 'AX', 'PM', 'GL', 'BL', 'SX', 'AW', 'CW', 'WF', 'PF', 'NC', 'TF', 'AI', 'BM', 'IO', 'VG', 'KY', 'FK', 'MS', 'PN', 'SH', 'GS', 'TC', 'AD', 'LI', 'MC', 'SM', 'VA', 'JE', 'GG', 'GI', 'CH',];
type PrebidAdUnit = { code: string, bids: any[] };
type RequestBidsOptions = { adUnitCodes?: string[], timeout?: number };
type RequestBidsReturn = Promise<{ bids: any, timedOut: boolean, auctionId: string }>;
type BuildVideoUrlOptions = { adUnit: PrebidAdUnit, params?: any, bid?: any };
type PrebidInstance = {
  cmd: Array<() => void>;
  bidderSettings?: any;
  adUnits: any[];

  requestBids(opts: RequestBidsOptions): RequestBidsReturn;
  processQueue(): void;
  setConfig(config: any): void;
  addAdUnits(adUnits: any[]): void;

  markWinningBidAsUsed(options: { adUnitCode: string }): void;
  getBidResponsesForAdUnitCode(code: string): { bids: any[] };

  adServers: {
    dfp: {
      buildVideoUrl(options: BuildVideoUrlOptions): string;
    }
  }
};

declare global {
  interface Window {
    [k: string]: PrebidInstance;
  }
}

export class PrebidProvider implements IProvider {
  public name = 'prebid';
  public globalName = 'pbjs';
  protected prevAdId = '';
  protected prebidPromise?: ReturnType<typeof loadPrebid>;

  protected iu: string;

  constructor(protected kv: KeyValues) {
    this.iu = `/100151972/${window.location.hostname}`;
  }

  public async configure(config: JarvisResponse) {
    this.iu = `/100151972${config.mcm ? ',' + config.mcm : ''}/${window.location.hostname}`;
    this.prebidPromise = loadPrebid(config);
    const prebid = await this.prebidPromise;
    const bids = config?.videoConfig?.bids || [];

    prebid.addAdUnits([
      this.createAdUnit('skippable', bids, { skip: 1 }),
      this.createAdUnit('unskippable', bids, { skip: 0 }),
    ]);
  }

  public async requestBids(params: any): Promise<IBid[]> {
    return new Promise(async (resolve) => {
      setTimeout(() => resolve([]), 4000);
      const pbjs = await this.prebidPromise;
      pbjs.requestBids({ adUnitCodes: ['skippable', 'unskippable'] })
        .then(({ bids }: any) => {
          const { skippable, unskippable } = bids || {};
          const allBids = [].concat(skippable?.bids, unskippable?.bids)
            .filter((bid: IBid['bid']) => !!bid)
            .map(bid => ({ bid, provider: this }));
          resolve(allBids);
        })
        .catch((err: any) => {
          console.error(err);
          resolve([]);
        });
    });
  }

  public buildVideoUrl(bid: IBid, noGoogle = false) {
    if (noGoogle) return this.buildSimpleUrl(bid);
    return new Promise(async (resolve, reject) => {
      setTimeout(() => reject('buildVideoUrl timeout.'), 1000);
      const { adUnitCode, adId } = bid.bid!;
      const pbjs = await this.prebidPromise;
      const adUnit = pbjs.adUnits.find((u: any) => u.code === adUnitCode);
      const videoUrl = pbjs.adServers.dfp.buildVideoUrl({
        adUnit,
        params: {
          iu: this.iu,
          sz: '1x1|640x360|640x390|640x480',
          ppid: this.kv.getPPID(),
          cust_params: this.kv.getObject({ retryOnError: '0' })
        }
      });
      pbjs.markWinningBidAsUsed({ adId } as any);
      resolve(videoUrl);
    }) as Promise<string>;
  }

  protected async buildSimpleUrl(bid: IBid) {
    return bid.bid.vastUrl || 'data:application/xml,' + encodeURIComponent(bid.bid.vastXml || '');
  }

  protected createAdUnit(code: string, bids: any[], { skip }: { skip: 1 | 0 }) {
    const placement = 1;
    const plcmt = 1;
    const pos = 1;
    const { hostname } = window.location;
    const defaultSize = [640, 480];
    return {
      code,
      bids,
      sizes: [[1, 1], [640, 360], [640, 390], defaultSize],
      ortb2Imp: {
        ext: {
          gpid: `/100151972/${hostname}/ark_pre-roll`,
          data: { pbadslot: `/100151972/${hostname}/ark_pre-roll` }
        }
      },
      mediaTypes: {
        video: {
          context: 'instream',
          skip,
          placement,
          pos,
          plcmt,
          startdelay: 0,
          minduration: 1,
          maxduration: 32,
          w: defaultSize[0],
          h: defaultSize[1],
          playerSize: defaultSize,
          linearity: 1,
          delivery: [1, 2],
          api: [1, 2, 3, 4, 5, 6],
          protocols: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
          playbackmethod: [3],
          playbackend: 1,
          mimes: ["application/javascript", "video/mp4"].concat(getSupportedMimes()),
        },
      },
    };
  }
}
