import { IAdsService } from "../../types/IAdsService";

import { PrebidProvider } from "../providers/prebid-provider";
import { JarvisService } from "../jarvis-service";
import { IBid, IProvider } from "../provider";
import { AmazonProvider } from "../providers/amazon-provider";
import { KeyValues } from "../keyvalues";
import { IConfig } from "../../types/IConfig";

const vgwTag = 'https://ams.cdn.arkadiumhosted.com/advertisement/video/vgw/vgw.xml';
const devTag = 'https://ams.cdn.arkadiumhosted.com/advertisement/video/static/advantage-skippable.xml';

export class WeSellService implements IAdsService {
  private iu: string;
  protected providers: IProvider[];
  protected jarvis: JarvisService;
  protected configured: Promise<any>;
  protected correlator = (Math.random()*1e15).toFixed(0);
  protected keyValues = new KeyValues(() => document.location.href, window.localStorage, document.cookie);
  protected config?: IConfig;

  constructor() {
    const { hostname } = window.location;
    this.iu = `/100151972/${hostname}`;
    this.providers = [ new PrebidProvider(this.keyValues), new AmazonProvider(this.keyValues) ];
    this.jarvis = new JarvisService(hostname);
    this.configured = this.jarvis.fetch().then((config) => {
      this.keyValues.setKeyValue('adsABTest', String(config.id));
      this.iu = `/100151972${config.mcm ? ',' + config.mcm : ''}/${hostname}`;
      return Promise.allSettled(this.providers.map(p => p.configure(config)));
    });
  }

  public request(config: IConfig): Promise<IBid[]> {
    for (let [key, value] of JSON.parse(config?.keyvalues || '[]')) {
      this.keyValues.setKeyValue(key, value);
    };
    this.keyValues.setKeyValue('position', config.type || '');
    const isDev = config.isDev;
    const duration = config.duration || 30;

    if (document.location.href.includes('ark_preroll_test=true')) {
      return Promise.resolve([{ bid: { cpm: 0 }, provider: this.createStubProvider(devTag) }]);
    }

    if (document.location.href.includes('games.vgwplay.com')) {
      return Promise.resolve([{ bid: { cpm: 0 }, provider: this.createStubProvider(vgwTag) }]);
    }

    if (isDev) {
      return Promise.resolve([{ bid: { cpm: 0 }, provider: this.createStubProvider(devTag) }]);
    }

    const keyValues = this.keyValues.getObject({ retryOnError: '0' });

    return this.configured
      .then(() => Promise.allSettled(this.providers.map(p => p.requestBids({ duration, keyValues, ppid: this.keyValues.getPPID() }))))
      .then((promises) => {
        const sortedBids = promises
          .flatMap(p => p.status === 'fulfilled' ? p.value: [])
          .filter(({ bid }) => bid?.cpm > 0)
          .sort((a, b) => b?.bid?.cpm - a?.bid?.cpm);
        return sortedBids.concat(this.fallbackBids());
      }).catch((err) => this.fallbackBids());
  }

  protected fallbackBids(): IBid[] {
    const ppid = this.getPPID();
    const url = encodeURIComponent(document.location.href);
    const bid = { cpm: 0 };

    const fallbackUrl1 = "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480" +
      "&iu=" + this.iu +
      "&cust_params=" + this.keyValues.getUrlParam({ retryOnError: '2' }) +
      "&impl=s" +
      "&gdfp_req=1" +
      "&env=vp" +
      "&output=vast" +
      "&unviewed_position_start=1" +
      "&vad_type=linear" +
      "&url=" + url +
      "&description_url=" + url +
      "&correlator=" + parseInt(Math.random() * 1e15 + '');

    const fallbackUrl2 = 'https://pubads.g.doubleclick.net/gampad/ads?iu=' + this.iu +
      '&url=' + url +
      '&description_url=' + url +
      '&trt=2' +
      '&plcmt=1' +
      '&tfcd=0&npa=0&sz=1x1%7C640x480' +
      '&afvsz=200x200%2C250x250%2C300x250%2C336x280%2C450x50%2C468x60%2C480x90' +
      "&cust_params=" + this.keyValues.getUrlParam({ retryOnError: '3' }) +
      '&min_ad_duration=1000&max_ad_duration=31000' +
      (this.iu.includes(',') ? '&ipd=arkadium.com' : '') +
      (ppid ? '&ppid=' + ppid : '') +
      '&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=' + this.correlator;

    const provider = this.createStubProvider(Math.random() > 0.5 ? fallbackUrl1 : fallbackUrl2);

    return [ { bid, provider } ];
  }

  protected getPPID() {
    try {
      return localStorage.getItem('_ppid');
    } catch (_) {
      return;
    }
  }

  protected createStubProvider(url: string): IProvider {
    const noImaUrl = 'https://optimized-by.rubiconproject.com/a/api/vast.xml?account_id=17214&site_id=161772&zone_id=775734&size_id=201&p_aso.video.protocols=2,5,3,6,7,8&p_aso.video.maxduration=30&p_aso.video.mimes="application/javascript","video/mp4"&p_aso.video.playbackmethod=3&p_aso.video.api=2';
    return {
      name: 'null',
      configure() {},
      requestBids: () => Promise.resolve([]),
      buildVideoUrl: (bid, noIma) => Promise.resolve(noIma ? noImaUrl : url),
    };
  }
}
